/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "No es ", React.createElement(_components.a, {
    href: "https://litox9.wordpress.com/2010/05/09/open-street-map/",
    title: "Open Street Map"
  }, "la primera vez que hablo de Open Street Map"), ", el mapa libre, últimamente me he vuelto a interesar por él y he empezado a mejorar los planos de mi población natal."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "http://ojw.dev.openstreetmap.org/StaticMap/?lat=39.482&lon=-0.4433&z=13&w=480&h=300&mode=Export&show=1",
    alt: "OpenStreetMap"
  })), "\n", React.createElement(_components.p, null, "La anterior web de ", React.createElement(_components.a, {
    href: "http://www.openstreetmap.es/",
    title: "OpenStreetMap.es"
  }, "OpenStreetMap.es"), " ahora se ha convertido en un blog de noticias sobre OSM en castellano. Además he encontrado nuevas webs con aplicaciones orientadas a otros ámbitos:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.openrouteservice.org/index.php#",
    title: "openrouteservice.org"
  }, "OpenRouteService.org"), ":Calcula las rutas, tanto en coche, bici o a pie, es muy flexible y además permite localizarte en el mapa mediante la API de localización web de HTML5."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.opentouchmap.org/",
    title: "opentouchmap"
  }, "OpenTouchMap"), ": Pensado para dispositivos móviles, es muy manejable con la pantalla táctil."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.openstreetmap.org/?lon=-3&lat=40&zoom=6"
  }, "www.openstreetmap.org/?lon=-3&lat=40&zoom=6"), " : Podemos poner un mapa de OSM en la posición que nosotros queramos mediante los parámetros lon (longitud), lat (latitud) y zoom."), "\n"), "\n", React.createElement(_components.p, null, "Esto es todo por ahora, otro día más."), "\n", React.createElement(_components.p, null, "Saludos"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
